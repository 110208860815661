import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
}

export const BannerCTA = memo(function BannerCTA({ items }: Props) {
  if (!items) {
    return null
  }

  return (
    <Container>
      <FadeInUp>
        <Wrapper dial={5} row stretch wrap>
          {items.slice(0, 2).map((item, index) => (
            <Item key={index} {...item} />
          ))}
        </Wrapper>
      </FadeInUp>
    </Container>
  )
})

const Container = styled.section`
  margin: 5.875rem auto 6.1875rem;

  @media (max-width: 1023px) {
    margin: 3.75rem auto;
  }
`

const Wrapper = styled(FlexBox)``
