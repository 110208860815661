import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import React, { memo } from 'react'

export interface Props {
  brand?: string
  description?: string
  title?: string
}

export const SimpleIntro = memo(function SimpleIntro({
  brand,
  description,
  title,
}: Props) {
  return (
    <Container>
      <Wrapper>
        {brand ? (
          <FadeInUp>
            <Brand style={{ backgroundImage: `url(${brand})` }} />
          </FadeInUp>
        ) : null}
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 6rem auto 8.125rem;
  padding: 0 10.972vw;
  text-align: center;

  @media (max-width: 1199px) {
    padding-right: 1.9375rem;
    padding-left: 1.9375rem;
  }

  @media (max-width: 1023px) {
    margin: 3.75rem auto 5.625rem;
  }
`

const Wrapper = styled.div`
  max-width: 744px;
  margin: auto;
`

const Brand = styled.div`
  width: 4.5rem;
  height: 3.75rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto 0.375rem;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 3.25rem;

  @media (max-width: 1023px) {
    font-size: 1.875rem;
    line-height: 2.4375rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  margin-top: 1.875rem;

  p {
    margin-top: inherit;
  }
`
