import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  label?: string
  title?: string
  URL: string
}

export const Item = memo(function Item({ label, title, URL }: Props) {
  if (!URL) {
    return null
  }

  return (
    <Container to={URL}>
      {label ? <Label>{label}</Label> : null}
      {title ? <Title>{title}</Title> : null}
    </Container>
  )
})

const Container = styled(Link)`
  margin: 0 10vw;
  padding: 3.625rem 0;
  position: relative;
  text-align: center;
  &:hover {
    div {
      &:after {
        animation: lineAnimation 1s forwards;
      }
    }
  }
  &:before {
    content: '';
    width: 106px;
    height: 106px;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  @keyframes lineAnimation {
    49% {
      right: 0;
      left: 100%;
    }
    50% {
      left: 0;
      right: 100%;
    }
    100% {
      background: ${({ theme }) => theme.colors.variants.primaryLight1};
      right: 0;
      left: 0;
    }
  }

  @media (max-width: 1399px) {
    margin: 0 7vw;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin: auto;
    padding: 2.875rem 0;
    &:before {
      width: 66px;
      height: 66px;
    }
  }
`

const Label = styled.div`
  max-width: 350px;
  color: ${({ theme }) => theme.colors.variants.neutralLight2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.125rem;
  line-height: 1.75rem;
  margin: 0 auto 0.375rem;
  position: relative;
  z-index: 2;

  @media (max-width: 1023px) {
    font-size: 1.625rem;
    line-height: 2.1875rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.125rem;
  line-height: 2.5rem;
  padding-bottom: 0.5rem;
  position: relative;
  &:after {
    content: '';
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    position: absolute;
    bottom: -0.5rem;
    right: 0;
    left: 0;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1023px) {
    font-size: 1.625rem;
    line-height: 2.1875rem;
    &:after {
      display: none;
    }
  }
`
